<template>
  <b-card :header="class_name">
    <div class="chart-wrapper">
      <q-linear-progress color="blue-grey-6" indeterminate :class="{ invisible: !loading }" />
      <!-- <QuotaGraph chartId="chart-line-01" :chartData="chartData" :width="750" :height="250" /> -->

      <!-- :pagination.sync="pagination" -->
      <!-- :allow_searchbar="false" -->
      <Search
        ref="searchRef"
        :default_per_page="25"
        :class_name="this.class_name"
        :stateName="'quota_page_'"
        :small="small"
        :allow_bottom_pagination="false"
        :allow_relationships_filter="true"
        :allow_predefined_filter="true"
        :allow_properties_filter="true"
        :allow_json_filter="true"
        :allow_select_fields="true"
        :allow_map="false"
        :enable_map_default="false"
        :_index_params="index_params"
        @update:pagination="
          pagination => {
            convertForGraph(pagination.data);
          }
        "
      >
        <template v-slot:search_top_slot="{}">
          <b-row class="toggles">
            <b-col cols="6" sm="4" md="4" xl="3" class="my-1">
              <b-form-group label="Linear/Series" :label-cols="12" :label-cols-sm="8">
                <c-switch
                  class="mx-2"
                  v-model="is_compact_mode"
                  color="success"
                  :size="small ? 'sm' : null"
                  label
                  outline
                  :dataOn="'\u2713'"
                  :dataOff="'\u2715'"
                  :value="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <QuotaGraph chartId="chart-line-01" :chartData="chartData" :width="750" :height="250" :is_compact_mode="is_compact_mode" />
        </template>
      </Search>
    </div>
  </b-card>
</template>
<script>
import { Switch as cSwitch } from '@coreui/vue';
import { QLinearProgress, QSpinner } from 'quasar';
import { hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';

export default {
  components: {
    QuotaGraph: () => import('./QuotaGraph'),
    QLinearProgress,
    QSpinner,
    hexToRgba,
    cSwitch,
    Search: () => import('@/descriptor/coreui/Search'),
  },
  data() {
    return {
      loading: null,
      is_compact_mode: true,
      chartData: {},
    };
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    class_name: {
      type: String,
      required: true,
    },
    label_relationships: {
      type: Array,
      required: false,
    },
    relationship_name: {
      type: String,
      required: true,
    },
    label_property_key: {
      type: String,
      required: true,
    },
    value_property_key: {
      type: String,
      required: true,
    },
  },
  computed: {
    index_params() {
      let with_relationships = [];

      with_relationships.push({
        from_class_name: this.class_name,
        relationship_name: this.relationship_name,
      });

      _.each(this.label_relationships, label_relationship => {
        with_relationships.push({
          from_class_name: this.class_name,
          relationship_name: label_relationship['relationship_name'],
        });
      });

      return {
        with_relationships: with_relationships,
      };
    },
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    init() {},
    extractValues(datasets, dataset_datetime, quotas, labels = []) {
      _.each(quotas, quota => {
        let dataset_label = labels.length ? labels.join(' - ') + ' - ' + quota[this.label_property_key] : quota[this.label_property_key];

        let dataset_color_hex8 = quota['color'] || '#CCCCCC';

        let opacity = 50;
        let dataset_color_rgba = dataset_color_hex8 ? hexToRgba(dataset_color_hex8.substring(0, 7), opacity) : '#CCC';

        let dataset_value = _.get(quota[this.$d.getRelationPivotKeyByName(this.class_name, this.relationship_name)], [this.value_property_key]);

        // console.log(dataset_datetime, dataset_label, dataset_value);

        let dataset = _.find(datasets, { label: dataset_label });

        if (!dataset) {
          datasets.push({
            label: dataset_label,
            backgroundColor: dataset_color_rgba,
            //   backgroundColor: dataset_color_hex8,
            // steppedLine: 'after',
            steppedLine: 'before',
            data: [
              {
                x: dataset_datetime,
                y: dataset_value,
              },
            ],
          });
        } else {
          dataset.data.push({
            x: dataset_datetime,
            y: dataset_value,
          });
        }
      });
    },
    extractLabelsAndValues(datasets, dataset_datetime, quotas, label_relationships, timeseries_value, labels = []) {
      // console.log(remaining_label_relationships, timeseries_value);

      let remaining_label_relationships = _.clone(label_relationships);
      let label_relationship = remaining_label_relationships.shift();

      if (label_relationship) {
        // console.log(label_relationship, timeseries_value);
        let relations = timeseries_value[this.$d.getRelationAliasByNames(this.class_name, label_relationship['relationship_name'])];
        // console.log(relations);

        _.each(relations, relation => {
          let next_labels = _.clone(labels);
          let label = relation[label_relationship['property_key']];
          // console.log(next_labels, label, timeseries_value);
          next_labels.push(label);

          if (remaining_label_relationships.length > 0) {
            // console.log('continue', next_labels);
            this.extractLabelsAndValues(datasets, dataset_datetime, quotas, remaining_label_relationships, timeseries_value, next_labels);
          } else {
            // console.log('end', next_labels);
            // next_labels = [label]; //TODO: workaround, have not tested recursive
            this.extractValues(datasets, dataset_datetime, quotas, next_labels);
          }
        });
        // labels = []; // clear
      }
    },
    convertForGraph(timeseries_values) {
      // console.log(timeseries_values);
      let datasets = [];

      _.each(timeseries_values, timeseries_value => {
        let quotas = timeseries_value[this.$d.getRelationAliasByNames(this.class_name, this.relationship_name)];
        let dataset_datetime = timeseries_value['datetime'];
        // let dataset_remarks = timeseries_value['remarks'];

        // console.log(dataset_datetime);

        if (!this.label_relationships) {
          this.extractValues(datasets, dataset_datetime, quotas);
        } else {
          this.extractLabelsAndValues(datasets, dataset_datetime, quotas, this.label_relationships, timeseries_value);
        }
      });

      console.log(datasets);

      datasets.forEach(dataset => {
        //sort data by date
        //ref: https://stackoverflow.com/a/40965804/3553367
        dataset.data.sort(function compare(a, b) {
          var dateA = new Date(a.x);
          var dateB = new Date(b.x);
          return dateA - dateB;
        });
      });

      this.chartData = {
        datasets: datasets,
      };
    },
  },
};
</script>
